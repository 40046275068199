import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../App.css';
import mnkLogo from '../../images/mnkLogo.png';
import controllerLogo from '../../images/controllerLogo.png';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.defaults.headers.common['x-api-key'] = 'makewarzonegreatagain';

const toastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark', 
    style: {
        background: '#1a1a1a', 
        color: '#ffffff', 
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
    },
};

const Dashboard = () => {
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const userId = params.get('userId');
    const userPassword = params.get('password');
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [licenseKey, setLicenseKey] = useState('');
    const [loading, setLoading] = useState(true); 

    // Add calculateDaysLeft function
    const calculateDaysLeft = (expireDate) => {
        const now = new Date();
        const expiration = new Date(expireDate);
        const diffTime = expiration - now;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert time difference to days
        return diffDays > 0 ? `${diffDays} days left` : 'Expired';
    };

    const fetchUserData = async (id) => {
        try {
            setLoading(true); 
            const response = await axios.get(`https://sweatbuilds-master-production.up.railway.app/users/${id}`);
            const userData = response.data;
            setUser(userData);
            setPassword(userData.password); 
            Cookies.set('user', JSON.stringify(userData), { expires: 1 / 24 }); 
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const userCookie = Cookies.get('user');
        if (userCookie) {
            const userData = JSON.parse(userCookie);
            setUser(userData);
            setPassword(userData.password); // Ensure password is set on first login
            fetchUserData(userData._id); // Fetch fresh data from server
            handleRefresh();
        } else if (userId) {
            fetchUserData(userId); // Fetch user data directly if not in cookie
        } else {
            navigate('/login');
        }
    
        if (userPassword) {
            setPassword(userPassword); // Set password if available in URL parameters
        }
    }, [userId, userPassword, navigate]);

    const handleRefresh = async () => {
        try {
            const response = await axios.get(`https://sweatbuilds-master-production.up.railway.app/users/${user._id}`);
            const updatedUser = response.data;
    
            setUser(updatedUser);  // Update the frontend state with the latest user data
            Cookies.set('user', JSON.stringify(updatedUser), { expires: 1 / 24 });  // Update the cookie with new user data
    
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleActivate = async () => {
        try {
            const response = await axios.post('https://sweatbuilds-master-production.up.railway.app/api/supreme/activate', {
                userId: user._id, // Pass the user ID
                licenseKey, // Pass the license key entered by the user
            });
    
            if (response.data.message === 'Key activated successfully!') {
                toast.success(response.data.message, toastOptions); // Success toast
                setShowModal(false); // Close the modal after activation
                handleRefresh(); // Refresh data to update state with newly activated key
            }
        } catch (error) {
            toast.error('Activation failed. Please check your key and try again.', toastOptions); // Error toast
            console.error('Error activating key:', error);
        }
    };

    const handleResetHWID = async (productId) => {
        try {
            const product = user.products.find(p => p.productId === productId);
    
            if (!product) {
                toast.error('Product not found.', toastOptions);
                return;
            }
    
            const lastReset = new Date(product.lastReset);
            const now = new Date();
            const diffTime = now - lastReset;  // Difference in milliseconds
            const hoursSinceLastReset = diffTime / (1000 * 60 * 60);  // Convert to hours
    
            if (hoursSinceLastReset < 24) {
                toast.warning(`You can reset your HWID in ${Math.ceil(24 - hoursSinceLastReset)} hours.`, toastOptions);
                return;
            }
    
            const response = await axios.post('https://sweatbuilds-master-production.up.railway.app/api/supreme/reset-hwid', {
                userId: user._id,
                productId,
            });
    
            if (response.data.message === 'HWID reset successfully!') {
                toast.success(response.data.message, toastOptions);
    
                // Fetch updated user data to refresh frontend state after HWID reset
                await handleRefresh();  // Ensure you update user state with latest data
    
            } else {
                toast.warning(response.data.message, toastOptions);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                const timeLeftMessage = error.response.data.message;
                toast.warning(timeLeftMessage, toastOptions);
            } else {
                toast.error('HWID reset failed. Please try again later.', toastOptions);
            }
            console.error('Error resetting HWID:', error);
        }
    };

    // Add a function to handle the download action
    const handleDownload = (productId) => {
        // You can add the actual download logic here.
        // For example, navigate to the download link based on productId.
        const downloadLink = productId === 0 
            ? 'https://zenw95r014.ufs.sh/f/011YBisLLd4PPbhLz022CHENgIJrjaMWozPqOcnU1h6e5lTX'  // Controller Download
            : 'https://3hoh1r8e3g.ufs.sh/f/yBYf7fkJ0sedIcDsYBFqkDi5gVRJ3XQIYKHLSedwTjNm2ZG4'; // MNK Download
        
        window.location.href = downloadLink;
    };
    
    if (loading) {
        return <div>Loading...</div>; 
    }

    if (!user) {
        return <div>Loading...</div>;
    }

    const discordName = user.discordName.charAt(0).toUpperCase() + user.discordName.slice(1);

    return (
        <div className="dashboard">
         <ToastContainer />
            <h2 className="welcome-message">Welcome, {discordName}!</h2>
            <div className="card profile-info">
                <img
                    className="discord-avatar"
                    src={`https://cdn.discordapp.com/avatars/${user.discordId}/${user.discordAvatar}.png`}
                    alt="Avatar"
                />
                <h2>Profile Information</h2>
                <p>
                    <strong>Username: </strong>
                    {user.discordId}
                </p>
                <div className="password-container">
                    <p>
                        <strong>Password:</strong>
                    </p>
                    <span className={`user-password ${showPassword ? 'show' : ''}`}>
                        <span>{password}</span>
                    </span>
                    <button className="toggle-button" onClick={togglePasswordVisibility}>
                        {showPassword ? 'Hide' : 'Show'}
                    </button>
                </div>
                {user.isAdmin && (
                <button onClick={() => navigate('/admin-panel')} className="admin-button">
                    Admin Panel
                </button>
                )}
            </div>
            <div className="card product-info" style={{marginBottom: '100px'}}>
                <h2>Your Products</h2>
                {user.products.length === 0 ? (
                    <>
                        <div className="product">
                            <img src={controllerLogo} alt="Controller Logo" />
                            <h3>Vatos Supreme Controller</h3>
                            <p>
                                <strong>License Key: </strong> N/A
                            </p>
                            <p>
                                <strong>Hwid: </strong>N/A
                            </p>
                            <p>
                                <strong>Expires at: </strong>N/A
                            </p>
                            <button className="buy-button" onClick={() => navigate('/buy')}>
                                Buy
                            </button>
                            <button className="activate-button" onClick={() => setShowModal(true)}>
                                Activate
                            </button>
                        </div>
                        <div className="product">
                            <img src={mnkLogo} alt="MnK Logo" />
                            <h3>Vatos Supreme MnK</h3>
                            <p>
                                <strong>License Key: </strong>N/A
                            </p>
                            <p>
                                <strong>Hwid: </strong>N/A
                            </p>
                            <p>
                                <strong>Expires at: </strong>N/A
                            </p>
                            <button className="buy-button" onClick={() => navigate('/buy')}>
                                Buy
                            </button>
                            <button className="activate-button" onClick={() => setShowModal(true)}>
                                Activate
                            </button>
                        </div>
                    </>
                ) : (
                    user.products.map((product, index) => (
                        <div key={index} className="product">
                            <img
                                src={product.productId === 0 ? controllerLogo : mnkLogo}
                                alt={product.productId === 0 ? 'Controller Logo' : 'MnK Logo'}
                            />
                            <h3>{product.productId === 0 ? 'Vatos Supreme Controller' : 'Vatos Supreme MnK'}</h3>
                            <p>
                                <strong>License Key: </strong>
                                {product.licenseKey || 'N/A'}
                            </p>
                            <p>
                                <strong>Hwid: </strong>
                                {product.hwid || 'N/A'}
                            </p>
                            <p>
                                <strong>Expires at: </strong>
                                {product.expireAt ? calculateDaysLeft(product.expireAt) : 'N/A'}
                            </p>
                            {(product.licenseKey === null || calculateDaysLeft(product.expireAt) === 'Expired') && (
                                <>
                                    <button className="buy-button" onClick={() => navigate('/buy')}>
                                        Buy
                                    </button>
                                    <button className="activate-button" onClick={() => setShowModal(true)}>
                                        Activate
                                    </button>
                                </>
                            )}
                            {calculateDaysLeft(product.expireAt) !== 'Expired' && (
                                <>
                                    <button
                                        className="reset-button"
                                        onClick={() => handleResetHWID(product.productId)}
                                    >
                                        Reset HWID
                                    </button>
                                    {/* Add Download button if the product is not expired */}
                                    <button
                                        className="download-button"
                                        onClick={() => handleDownload(product.productId)}
                                    >
                                        Download
                                    </button>
                                </>
                            )}
                        </div>
                    ))
                )}
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Activate Your License</h2>
                        <input
                            type="text"
                            value={licenseKey}
                            onChange={(e) => setLicenseKey(e.target.value)}
                            placeholder="Enter your license key"
                        />
                        <button className="activate-button" onClick={handleActivate}>
                            Activate
                        </button>
                        <button className="cancel-button" onClick={() => setShowModal(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
