import React, { useState } from 'react';
import '../../Buy.css';
import ProductCard from '../ProductCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faShieldAlt, faClock, faClipboard, faSyncAlt, faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import controllerLogo from '../../images/controllerLogo.png';
import mnkLogo from '../../images/mnkLogo.png';

const Buy = () => {
    const [iframeSrc, setIframeSrc] = useState('');
    const [showModal, setShowModal] = useState(false);
    
    const products = [
        {
            imgSrc: controllerLogo,
            title: 'Vatos Supreme Controller',
            prices: [
                { duration: '1 Month', amount: 15, info: '30 Days Access!' },
                { duration: '2 Months', amount: 27.5, info: '60 Days Access!' },
                { duration: '3 Months', amount: 35, info: '90 Days Access!' },
                { duration: '1 Year', amount: 150, info: '365 Days Access!' },
            ],
            productPath: 'vatos-supreme-controller' // Add productPath
        },
        {
            imgSrc: mnkLogo,
            title: 'Vatos Supreme MnK',
            prices: [
                { duration: '1 Month', amount: 15, info: '30 Days Access!' },
                { duration: '2 Months', amount: 27.5, info: '60 Days Access!' },
                { duration: '3 Months', amount: 35, info: '90 Days Access!' },
                { duration: '1 Year', amount: 150, info: '365 Days Access!' },
            ],
            productPath: 'vatos-supreme-mnk' // Add productPath
        },
    ];

    const features = [
        { icon: faCrosshairs, title: 'Boost Aim Assist', description: 'Enhance your aim with advanced settings.' },
        { icon: faShieldAlt, title: '100% Safe To Use', description: 'Guaranteed protection and security.' },
        { icon: faClock, title: 'Instant Delivery', description: 'Get your product instantly after purchase.' },
        { icon: faClipboard, title: 'Easy Guides', description: 'Step-by-step guides to help you setup.' },
        { icon: faGamepad, title: 'Supports 10+ Games', description: 'Compatible with a wide range of games.' },
        { icon: faSyncAlt, title: 'Free Updates', description: 'Enjoy lifetime free updates on all purchases.' },
    ];

    const testimonials = [
        {
            header: "GamerX1988",
            username: "@GamerX1988",
            text: "Vatos Supreme has completely transformed my gaming experience. The features are incredible and the support is top-notch."
        },
        {
            header: "MirageFPS",
            username: "@MirageFPS",
            text: "I can't imagine gaming without Vatos Supreme now. It's a must-have for any serious gamer."
        },
        {
            header: "LoneWolf_x1",
            username: "@LoneWolf_x1",
            text: "Even as a casual gamer, Vatos Supreme has made a huge difference. The interface is user-friendly and the performance is great."
        },
        {
            header: "TechGuru!2",
            username: "@TechGuru!2",
            text: "The best gaming software I've ever used. The features are well thought out and the performance is unmatched."
        },
        {
            header: "RisingStarDE",
            username: "@RisingStarDE",
            text: "Vatos Supreme is a game-changer. The level of detail and customization available is amazing."
        }
    ];

    const handleBuyNowClick = () => {
        setIframeSrc(`https://vatossupreme.bgng.io/embed/vatos-supreme`);
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
        setIframeSrc('');
    };

    return (
        <div className="App">
            <div className="buy" style={{ marginTop: '150px', marginBottom: '2px' }}>
                <p>For purchases, please visit <a href="https://vatoshub.com/collections/all">Vatos Hub</a></p>
                <div className="card-2-container">
                    {products.map((product, index) => (
                        <ProductCard
                            key={index}
                            imgSrc={product.imgSrc}
                            title={product.title}
                            prices={product.prices}
                            onBuyNowClick={() => handleBuyNowClick()} 
                        />
                    ))}
                </div>

                {showModal && (
                    <div className="sellpass-modal">
                        <div className="sellpass-iframe-backdrop" onClick={closeModal}></div>
                        <div className="sellpass-iframe-wrapper show">
                            <button className="close-modal" onClick={closeModal}>×</button>
                            <iframe
                                className="sellpass-iframe"
                                src={iframeSrc}
                                style={{ border: 0 }}
                                allowFullScreen
                                title="Billgang Product"
                            />
                        </div>
                        <div className="sellpass-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )}

                <div className="features-container" style={{ marginBottom: '0px' }}>
                    <h2 className="features-title">Key Features</h2>
                    <div className="features-list">
                        {features.map((feature, index) => (
                            <div key={index} className="feature">
                                <FontAwesomeIcon icon={feature.icon} size="2x" />
                                <h3>{feature.title}</h3>
                                <p>{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="testimonials-container" style={{ marginTop: '25px', marginBottom: '100px' }}>
                    <h2 className="testimonials-title">Testimonials</h2>
                    <div className="testimonials">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial-card">
                                <div className="testimonial-header">{testimonial.header}</div>
                                <div className="testimonial-username">{testimonial.username}</div>
                                <p className="testimonial-text">{testimonial.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Buy;
